import React from 'react'
import "./App.css";
import HomePage from './components/HomePage';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Faq from './components/Faq';
import Gameplay from './components/Gameplay';
// import DevBlog from './components/DevBlog';
import Team from './components/Team';
// import EmailForm from './components/EmailForm';
import NavContainer from './components/NavContainer';

function App() {

  return (
    <div>
      <NavContainer/>
    <div>
      
      <BrowserRouter>

          <Routes>
              <Route path="/" element={ <HomePage />} />
              <Route path="/faq" element={ <Faq />} />
              <Route path="/gameplay" element={ <Gameplay />} />
              {/* <Route path="/devblog" element={ <DevBlog />} /> */}
              <Route path="/team" element={ <Team />} />
          </Routes>

        </BrowserRouter>
    </div>
    <br/>
    <br/>
    <br/>
      <p  class="center">Homefront Harvest Copyright © 2020-2022 Jackalope Games</p>
    </div>

  );

}

export default App;
