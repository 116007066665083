function Team() {

    return (
        <div class="col-xs-1" align="center">
            <br/>
            <h1 class="text-warning">Team</h1>
            <br/>
            <br/>
            <script async data-uid="FORM ID GOES HERE" src="FORM JAVASCRIPT URL GOES HERE"></script>

        </div>
    );
  
  }

  export default Team