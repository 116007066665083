import NavBar from "./NavBar";
import { Container, Row, Col } from "react-bootstrap"

function NavContainer() {

    return (
        <Container >
            <Row >
                <Col xs={12} md={10}> <NavBar /></Col> 
            </Row>
        </Container>
    );
  
  }

  export default NavContainer