
function Gameplay() {

    return (

        <div class="col-xs-1" align="center">
            <div>
                <br/>
                <br/>
                <h1 class="text-warning">Game Play</h1>
                <br/>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/W3_MeYzgLSg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <br/>
                <br/>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/DRag3Ey8bfc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <br/>
            
            </div>
        </div>

    );
  
  }

  export default Gameplay