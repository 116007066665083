import SocialFollow from "./SocialFollow";

function NavBar() {

    return (

        <nav class="sticky-top navbar navbar-expand-sm" >
                <a href="/" class="navbar-brand nav-link">
                    <img class="rounded"  src="https://styles.redditmedia.com/t5_2e0lx6/styles/communityIcon_lz59m5c22ku61.png?width=256&s=bf0fb00f57a1ec2c14f083193f0abbccd8137445" height="35" alt="HH"/>
                </a>
                <a class="nav-link text-black" href="/faq" style={{fontWeight: "bold"}}>
                    FAQ</a>
                <a class="nav-link text-black" href="/gameplay" style={{fontWeight: "bold"}}>
                    Game Play</a>
                    {/* <a class="nav-link text-black" href="/devblog" >
                    Dev Blog</a> */}
                 <a class="nav-link text-black" href="/team" style={{fontWeight: "bold"}}>
                    Team</a>
                {/* <SocialFollow /> */}
        </nav>
    );
  
  }

  export default NavBar