function Faq() {

    return (
        <div class="col-xs-1" align="center">
            <br/>
            <h1 class="text-warning">Frequently Asked Questions</h1>
            <br/>
                <p class="text-black">Q:</p>
                <br/>
                <p class="text-black">Q:</p>
                <br/>
                <p class="text-black">Q:</p>
                <br/>
                <p class="text-black">Q:</p>
                <br/>
                <p class="text-black">Q:</p>
                <br/>
        </div>
    );
  
  }

  export default Faq