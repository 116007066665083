import React from 'react'
import RedditIcon from '../media/3225187_app_logo_media_popular_reddit_icon.png'
import TwitterIcon from '../media/5296514_bird_tweet_twitter_twitter logo_icon.png'
import { Container, Row, Col } from "react-bootstrap"

export default function SocialFollow() {

  return (

    <div class="center" > 
                <Container  class="center">
                   <br/>
                   <br/>
                   <br/>                    
                   <Row>
                    <Col></Col>
                        <Col></Col>
                        <Col >
                        <a href="https://www.reddit.com/r/Homefrontharvest/"
                                className="social" class="text-center">
                                <img src={RedditIcon} />
                            </a>
                        </Col> 

                        <Col >
                            <a href="https://twitter.com/HFHarvestGame"
                                className="social" >
                                <img src={TwitterIcon} />
                            </a>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                   <br/>
                </Container>
    </div>



  );

}

