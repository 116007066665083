import image from "../media/background.png";
import SocialFollow from "./SocialFollow"

function HomePage() {

    const styleObj = {
        fontSize: 30,
        color: "black",
        textAlign: "left",
        paddingTop: "100px",
    }

    return (
        
        <div>
            <div style={{ backgroundImage:`url(${image})`, 
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    width: '100vw',
                    height: '100vh'
                    }}>
                <br/>
                <br/>
                <h1 class="text-warning display-1 text-center">Homefront</h1>
                <h1 class="text-warning display-1 text-center">Harvest</h1>
                </div>
            <div class="container"> 
                    <br/>
                <div class="row text-center">
                    <div class="col-md-6">
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <img class="rounded" src={require("../media/wilfred.png")} width="350px"/>
                    </div>
                    <div class="col-md-6">
                        <p style={styleObj}><strong>Homefront Harvest, </strong>build and serve your community in a small town, a century ago. RPG in development, inspired by the early 1900s, Stardew Valley, and Thomas Pynchon.</p>
                        <p style={styleObj}><strong>Story </strong>story section</p>
                        <p style={styleObj}><strong>Features </strong>Features section</p>

                    </div>
                </div>
            </div>
            <SocialFollow />
            </div>
        );
  
  }

  export default HomePage
